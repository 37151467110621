<template>
  <div>
    <b-card class="mt-4" v-if="campaignSelected_text == ''">
      <b-tabs
        content-class="p-4 content-border"
        nav-class="people-analysis-tabs"
      >
        <b-tab lazy active>
          <template #title>
            <title-pie
              @CustomEvent_FormChange="changeSelected"
              :options="sectionsOptions"
              :selected="sectionSelected"
              :labels="['Valore', 'Goal']"
              :pieData="pieData.firstChart"
              target="sectionSelected"
              :showComponentSelected="false"
            ></title-pie>
          </template>
          <div class="d-flex">
            <!-- Mancano i dati per il Line-chart -->

            <!-- <line-chart
              class="w-100 line-chart"
              :chartData="lineChartData"
              :chartLabels="lineChartLabels"
              :chartTitle="sectionSelected"
            />
            <line-chart
              class="w-100 line-chart"
              :chartData="cumulative_lineChartData"
              :chartLabels="lineChartLabels"
              :chartTitle="'Cumulativo: ' + sectionSelected"
            /> -->
          </div>
        </b-tab>
        <b-tab lazy>
          <template #title>
            <title-pie
              :labels="['Media percentuale di tutti i KPI', 'Totale']"
              :pieData="pieData.secondChart"
              :value="avgTot.toFixed(0)"
              :value_etichetta="'%'"
              target="chapterSelected"
              :showPercentage="false"
              :showComponentSelected="false"
            ></title-pie>
          </template>
          <!-- Mancano i dati per il Line-chart -->
          <!-- <div class="d-flex">
            <line-chart
              class="w-100 line-chart"
              :chartData="lineChartData"
              :chartLabels="lineChartLabels"
              :chartTitle="chapterSelected"
            />
            <line-chart
              class="w-100 line-chart"
              :chartData="cumulative_lineChartData"
              :chartLabels="lineChartLabels"
              :chartTitle="'Cumulativo: ' + chapterSelected"
            />
          </div> -->
        </b-tab>
      </b-tabs>
    </b-card>
    <b-card class="mt-4" v-else>{{ campaignSelected_text }}</b-card>
  </div>
</template>

<script>
// import LineChart from "../LineChart.vue";

import TitlePie from "../DashBoard/TabTitles/TitlePie.vue";
import { mapGetters } from "vuex";

export default {
  name: "KpiComponent",
  components: {
    // LineChart,
    TitlePie,
  },
  props: {
    dataKpi: Array,
    campaignSelected_text: String,
  },
  service: null,
  created() {
    this.view_lang = this.default_lang.value || this.default_lang;
  },

  mounted() {
    // await this.service.create(this.filter).then((res) => {
    //   this.dataKpi = res;
    // });
    this.loadData();
  },
  data() {
    return {
      // dataKpi: null,

      avgTot: 0,

      sectionSelected: "0",
      sectionsOptions: [{ text: "Select Kpi", value: "0" }],

      pieData: {
        firstChart: [0, 0],
        secondChart: [0, 0],
      },

      lineChartData: [],
      lineChartLabels: [],

      view_lang: null,
    };
  },

  methods: {
    loadData() {
      // popolate selection options
      this.sectionsOptions.splice(1);

      this.dataKpi.forEach((element) => {
        element.text = element.friendlyName[this.view_lang];
        element.value = element.id;

        this.sectionsOptions.push(element);
      });

      // calc avarage % of all kpi
      const calcAvg = function(arr) {
        const arrLength = arr.length;
        return arr.reduce((acc, val) => {
          return acc + val.perc_completamento / arrLength;
        }, 0);
      };

      this.avgTot = calcAvg(this.dataKpi);
    },
    changeSelected(event) {
      this[event.target] = event.value.toString();
      console.log("changeSelected(event) event => ", event);

      // if (event.value == "All Kpis") {
      //   this.calcTotali();
      // } else {
      this.pieData.firstChart.length = 0;
      this.pieData.firstChart.push(event.element.valore);
      this.pieData.firstChart.push(event.element.goal);

      this.pieData.secondChart.length = 0;

      //   this.pieData.secondChart.push(event.element.perc_completamento);
      //   this.pieData.secondChart.push(100);

      //   this.lineChartData.length = 0;
      //   this.lineChartLabels.length = 0;
      // }
    },
    //     calcTotali() {
    //       this.pieData.firstChart.length = 0;
    //       this.pieData.secondChart.length = 0;

    //       let valoriTot = 0;
    //       let goalTot = 0;
    //       let percCompletamentoTot = 0;

    //       this.dataKpi.forEach((element) => {
    //         valoriTot += element.valore;
    //         goalTot += element.goal;
    //         percCompletamentoTot += element.perc_completamento;
    //       });

    //       percCompletamentoTot = percCompletamentoTot / this.dataKpi.length;

    //       this.pieData.firstChart.push(valoriTot);
    //       this.pieData.firstChart.push(goalTot);
    //       this.pieData.secondChart.push(percCompletamentoTot);
    //       this.pieData.secondChart.push(100);
    //     },
  },
  computed: {
    ...mapGetters(["languages"]),
    ...mapGetters(["default_lang"]),
    cumulative_lineChartData() {
      let array = [];
      let previousPosition = 0;
      this.lineChartData.forEach((element) => {
        previousPosition += element;
        element = previousPosition;
        array.push(element);
      });
      return array;
    },
  },
  watch: {
    dataKpi: {
      deep: true,
      handler() {
        this.sectionsOptions.length = 1;
        this.lineChartData.length = 0;
        this.lineChartLabels.length = 0;

        this.loadData();
      },
    },
  },
};
</script>
